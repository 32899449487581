import {createContext, ReactElement, FC, useState, useEffect} from 'react';
import {useDispatch, useSelector } from 'react-redux';
import {selectActiveWidget, selectActiveWidgetUpdatingStatus} from 'src/components/Widget/selectors';
import { Block, useCheckWidgetInstallation } from 'src/shared/hooks/useCheckWidgetInstallation';
import {usePutWidget} from "../../../../../shared/hooks/tanstackQuery/usePutWidget";
import {widgetsActions} from "../../../store";
import {useDebounce, useDebouncedCallback} from "use-debounce";
import {useDebouncedState} from "@mantine/hooks";
import {checkAndRefetchOnboardingData} from "../../../../../api/onboarding/checkAndRefetchOnboardingData";
import {WidgetBlockStatus} from "../../../types";

type InstallContextProps = {
  checked: boolean;
  error: boolean;
  data: Block | null;
  loading: boolean;
  checkInstallation: (callback: () => void) => Promise<void>;
  blockStatuses: WidgetBlockStatus[];
};
export const InstallationContext = createContext<InstallContextProps>({
  data: null,
  error: false,
  checked: false,
  loading: false,
  checkInstallation: async (callback: () => void) => {},
  blockStatuses: [],
});

interface InstallationProviderProps {
  children: ReactElement | ReactElement[];
}
export const InstallationProvider: FC<InstallationProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const activeWidget = useSelector(selectActiveWidget);
  const dispatch = useDispatch();

  const { error, data, checked, checkInstallation, blockStatuses } = useCheckWidgetInstallation(
    activeWidget?.id ?? 'widgetId',
    (val: boolean) => setLoading(val)
  );


  const {mutate: putWidget} = usePutWidget({
    onSuccess: () => {
      checkInstallation()
      checkAndRefetchOnboardingData();
      dispatch(widgetsActions.setWidgetUpdatingStatus('success'))
    }
  })

  const debouncedActiveWidget = useDebouncedCallback(putWidget,3000)

  useEffect(() => {
    if (activeWidget) {
      dispatch(widgetsActions.setWidgetUpdatingStatus('loading'))
      debouncedActiveWidget({
        id: activeWidget.id,
        videos: activeWidget.videos.map((video) => video.id),
        pages: activeWidget.pages.map((page) => page),
        settings: activeWidget.settings,
        type: activeWidget.type,
        name: activeWidget.name,
        status: activeWidget.status,
        allPages: activeWidget.allPages,
        page: activeWidget.page,
        productDetails: activeWidget.productDetails?.map((widgetProductDetail) => ({
          isVisible: widgetProductDetail.isVisible,
          order: widgetProductDetail.order,
          id: widgetProductDetail.id,
        })),
        previewBuyButtonSettings: activeWidget.settings_v2.layout.previewBuyButton.buyButtonSettings,
        previewProductCardPosition: activeWidget.settings_v2.layout.previewBuyButton.productCardPosition,
        previewBuyButtonPosition: activeWidget.settings_v2.layout.previewBuyButton.buyButtonPosition,
        isShowBuyButtonOnPreview: activeWidget.settings_v2.layout.previewBuyButton.buyButtonEnabled,
        isShowProductCardOnPreview: activeWidget.settings_v2.layout.previewBuyButton.enable,
        previewProductCardSettings: activeWidget.settings_v2.layout.previewBuyButton.previewProductCardSettings,
        isShowVideoProductCardBuyButton: activeWidget.settings_v2.layout.videoPlayerCTAButtons.buyButtonEnabled,
        videoProductCardBuyButtonPosition: activeWidget.settings_v2.layout.videoPlayerCTAButtons.buyButtonPosition,
        videoProductCardBuyButtonSettings: activeWidget.settings_v2.layout.videoPlayerCTAButtons.buyButtonSettings,
        isShowVideoProductCardViewButton: activeWidget.settings_v2.layout.videoPlayerCTAButtons.viewProductButtonEnabled,
        videoProductCardViewButtonPosition: activeWidget.settings_v2.layout.videoPlayerCTAButtons.viewProductButtonPosition,
        videoProductCardViewButtonSettings: activeWidget.settings_v2.layout.videoPlayerCTAButtons.viewProductButtonSettings,
      })
    }
  }, [activeWidget, debouncedActiveWidget, dispatch])

  return (
    <InstallationContext.Provider value={{ data, checked, error, loading, checkInstallation, blockStatuses }}>
      {children}
    </InstallationContext.Provider>
  );
};
