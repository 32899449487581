import React, {FC} from 'react';
import {
  BuyButtonKey,
  BuyButtonValue,
  LayoutKey,
  LayoutValue,
  SetLayoutV2ActionPayload, ViewButtonKey, ViewButtonValue
} from 'src/components/Widget/store';
import propWrapperStyles from '../PropWrapper.module.scss';
import {Box, ColorInput, createStyles, NumberInput, Select, Slider, TextInput, useMantineTheme} from '@mantine/core';
import {SegmentSwitch} from 'src/components/SegmentSwitch/SegmentSwitch';
import styles from './VideoPlayerSettings.module.scss';
import {
  BuyButtonIcon,
  PreviewCTAElementPosition,
  VideoPlayerProductCardCTAButtons, ViewButtonIcon,
  WidgetSettings,
  WidgetSettingsV2
} from 'src/components/Widget/types';
import {PropWrapper} from "../PropWrapper";
import {BuyButtonSettings, iconsList} from "../BuyButton/BuyButtonSettings";
import {useSelector} from "react-redux";
import {selectWidgetSettingsV2} from "../../../../selectors";
import {ChevronDown, Eye, ShoppingBag, ShoppingCart} from "tabler-icons-react";
import {PropWithSwitchAutoScroll} from "../PropWithSwitchAutoScroll/PropWithSwitchAutoScroll";
import {BorderHandlerParams, BorderSubmodule} from "../GenericComponents/BorderSubmodule/BorderSubmodule";

const headingFontWeight = [
  {
    value: 'light',
    label: 'Light',
  },
  {
    value: 'regular',
    label: 'Regular',
  },
  {
    value: 'bold',
    label: 'Bold',
  },
];
const headingAlignment = [
  {
    value: 'left',
    label: 'Left',
  },
  {
    value: 'center',
    label: 'Center',
  },
  {
    value: 'right',
    label: 'Right',
  },
];

interface SubmoduleProps {
  handler: (key: LayoutKey, value: LayoutValue) => void;
  layout: WidgetSettings['layout'];
}
interface BorderSubmoduleProps extends SubmoduleProps {
  showBorderThickness?: boolean;
}
export const BorderSubmodules: FC<BorderSubmoduleProps> = ({
  handler,
  layout,
  showBorderThickness,
}) => {
  return (
    <Box className={propWrapperStyles.submodule__container}>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Color</span>
        <ColorInput
          w={171}
          format="hex"
          placeholder="Pick a color"
          value={layout?.borderColor}
          onChange={(val) => handler('borderColor', val)}
        />
      </Box>
      {showBorderThickness && (
        <Box className={propWrapperStyles.submodule}>
          <span className={propWrapperStyles.submodule__title}>Thickness</span>
          <div className={styles.slider}>
            <Slider
              value={layout?.borderThickness}
              onChange={(val) => handler('borderThickness', val)}
              w={160}
              mr={12}
              max={10}
              min={0}
            />
            <NumberInput
              color="green"
              value={typeof layout?.borderThickness === 'number' ? layout?.borderThickness : 0}
              onChange={(val) => handler('borderThickness', val)}
              hideControls
              styles={() => ({
                input: { width: '42px', height: '26px', minHeight: '26px' },
              })}
              min={0}
              max={10}
              step={1}
              mr={6}
            />
            <div className={styles.slider__units}>px</div>
          </div>
        </Box>
      )}
    </Box>
  );
};

export const HeadingSubmodule: FC<SubmoduleProps> = ({ handler, layout }) => {
  return (
    <Box className={propWrapperStyles.submodule__container}>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Heading text</span>
        <TextInput
          w={261}
          placeholder="Your text"
          value={layout?.headingText}
          onChange={(e) => handler('headingText', e.target.value)}
        />
      </Box>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Font weight</span>
        <SegmentSwitch
          value={layout?.headingFontWeight ?? 'light'}
          data={headingFontWeight}
          onChange={(val) => handler('headingFontWeight', val)}
        />
      </Box>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Text color</span>
        <ColorInput
          w={171}
          format="hex"
          placeholder="Pick a color"
          value={layout?.headingTextColor}
          onChange={(val) => handler('headingTextColor', val)}
        />
      </Box>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Alignment</span>
        <SegmentSwitch
          value={layout?.headingAlignment ?? 'center'}
          data={headingAlignment}
          onChange={(val) => handler('headingAlignment', val)}
        />
      </Box>
    </Box>
  );
};

interface VideoPlayerBuyButtonSubmoduleProps {
  handler: (props: SetLayoutV2ActionPayload) => void;
  layout: WidgetSettingsV2['layout'];
}

const positionData = [
  {
    value: VideoPlayerProductCardCTAButtons.OverlayOnCard,
    label: 'Overlay on card',
  },
  {
    value: VideoPlayerProductCardCTAButtons.BellowCard,
    label: 'Below card',
  },
];
type IconProps = {
  size?: number;
  color?: string;
};
export const viewButtonsIconList = [
  {
    value: ViewButtonIcon.eye,
    label: 'Eye',
    icon: ({ size = 14, color }: IconProps) => (
      <Eye size={size} strokeWidth={1} color={color} className={styles.select__icon} />
    ),
  }, {
   value: 'None',
   label: 'None',
   icon: () => null,
  }
];
const useStyles = createStyles(() => ({
  select__icon: {
    left: '8px',
    '&::after': {
      display: 'block',
      content: '"-"',
      marginLeft: '4px',
    },
  },
  select__input: {
    '&[data-with-icon]': {
      paddingLeft: '42px',
    },
    paddingRight: 0,
  },
}));
export const ViewProductButtonSubmodule: FC<VideoPlayerBuyButtonSubmoduleProps> = ({ handler, layout }) => {
  const { classes } = useStyles();
  const viewButtonSettings = layout?.videoPlayerCTAButtons?.viewProductButtonSettings;
  const { colors } = useMantineTheme();

  const viewButtonHandler = (key: ViewButtonKey, value: ViewButtonValue) => {
    if(key === 'text' && typeof value === 'string' && value.length >= 15){
      return
    }
    handler({
      path: `videoPlayerCTAButtons.viewProductButtonSettings.${key}`,
      value,
    })
  }

  const borderHandler = (data: BorderHandlerParams) => {
    if (data.key === 'borderThickness' && typeof data.value === 'number') {
      handler({
        path: `videoPlayerCTAButtons.viewProductButtonSettings.borderThickness`,
        value: data.value,
      })
    } else if (data.key === 'borderColor') {
      handler({
        path: `videoPlayerCTAButtons.viewProductButtonSettings.borderColor`,
        value: data.value,
      })
    }
  }

  return (<Box>
    <PropWrapper propName="Button Position" alignContentLeft  propNameWithoutMargin>
      <SegmentSwitch
        value={layout?.videoPlayerCTAButtons?.viewProductButtonPosition ?? VideoPlayerProductCardCTAButtons.BellowCard}
        data={positionData}
        onChange={(val) => handler({
          value: val,
          path: 'videoPlayerCTAButtons.viewProductButtonPosition',
        })}
      />
    </PropWrapper>
    <PropWrapper propName="Button text" alignContentLeft >
      <TextInput
        w={228}
        value={layout?.videoPlayerCTAButtons.viewProductButtonSettings?.text ?? 'View'}
        onChange={(e) => viewButtonHandler('text', e.target.value)}
        placeholder="Your Text"
      />
    </PropWrapper>
    <PropWrapper propName="Icon" alignContentLeft >
      <Select
        w={98}
        icon={viewButtonsIconList
          .filter((i) => i.value === viewButtonSettings?.icon)[0]
          ?.icon({ size: 14, color: '#c1c2c5' })}
        data={viewButtonsIconList}
        value={viewButtonSettings?.icon}
        onChange={(val) =>{
          if (val === 'None') {
            viewButtonHandler('icon', '')
            return
          }
          viewButtonHandler('icon', val ?? '')
        }}
        rightSection={<ChevronDown color={colors.greyLight[2]} size={16} />}
        classNames={{
          input: classes.select__input,
          icon: classes.select__icon,
        }}
        styles={() => ({
          wrapper: {
            width: '100px',
          },
        })}
      />
    </PropWrapper>
    <PropWrapper propName="Text/icon color" alignContentLeft >
      <ColorInput w={171}
                  value={layout?.videoPlayerCTAButtons.viewProductButtonSettings?.color}
                  onChange={(val) => viewButtonHandler('color', val)}
      />
    </PropWrapper>
    <PropWrapper propName="Background color" alignContentLeft >
      <ColorInput
        w={171}
        value={viewButtonSettings?.backgroundColor}
        onChange={(val) => viewButtonHandler('backgroundColor', val)}
      />
    </PropWrapper>

    <PropWrapper propName="Button radius" alignContentLeft >
      <div className={styles.slider}>
        <Slider
          value={viewButtonSettings?.borderRadius}
          onChange={(val) => viewButtonHandler('borderRadius', val)}
          w={160}
          mr={12}
        />
        <NumberInput
          value={typeof viewButtonSettings?.borderRadius === 'number' ? viewButtonSettings.borderRadius : 0}
          onChange={(val) => viewButtonHandler('borderRadius', val)}
          hideControls
          styles={() => ({
            input: { width: '42px', height: '26px', minHeight: '26px' },
          })}
          min={0}
          max={100}
          step={1}
          mr={6}
        />
        <div className={styles.slider__units}>px</div>
      </div>
    </PropWrapper>
    <PropWithSwitchAutoScroll
      propName="Border"
      subModule={
        !viewButtonSettings?.showBorder ? null : <BorderSubmodule handler={borderHandler} buyButton={viewButtonSettings} />
      }
      checked={viewButtonSettings?.showBorder}
      onChange={() => {
        viewButtonHandler('showBorder', !viewButtonSettings?.showBorder)
      }      }
    />
  </Box>)
}

export const VideoPlayerBuyButtonSubmodule: FC<VideoPlayerBuyButtonSubmoduleProps> = ({ handler, layout }) => {

 const  buyButtonHandler = (key: BuyButtonKey, value: BuyButtonValue)=>{
   if(key === 'text' && typeof value === 'string' && value.length >= 15){
      return
   }
   handler({
     path: `videoPlayerCTAButtons.buyButtonSettings.${key}`,
     value,
   })
 }
 return (
   <Box>

     <PropWrapper propName="Buy button" alignContentLeft>
       <SegmentSwitch
         value={layout?.videoPlayerCTAButtons.buyButtonPosition ?? VideoPlayerProductCardCTAButtons.OverlayOnCard}
         data={positionData}
         onChange={(val) => handler({
           value: val,
           path: 'videoPlayerCTAButtons.buyButtonPosition',
         })}
       />
     </PropWrapper>
      <BuyButtonSettings
        buyButton={layout.videoPlayerCTAButtons.buyButtonSettings}
        handler={buyButtonHandler}
        showDivider={false}
      />
   </Box>
 )
}
