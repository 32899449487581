import Clarity from "@microsoft/clarity";

export const clarityIdentify = ({storeId, name}: {
  storeId: string;
  name: string;
}) => {
  try {
    if ('clarity' in window && typeof window.clarity === 'function') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.clarity("identify", storeId, undefined, undefined, name)
    }
  } catch (e) {
    console.error('Error identifying ', e)
  }
}
