import {SuccessResponse} from "@greatsumini/react-facebook-login/dist/types/response.type";
import {userActions} from "../../components/User/store";
import {videoListActions} from "../../components/Library/store";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {checkAndRefetchOnboardingData} from "src/api/onboarding/checkAndRefetchOnboardingData";
import {useEffect, useMemo, useRef, useState} from "react";
import {selectUser} from "../../components/User/selectors";
import {openSuccessModalFB} from "../../components/Meta/ConnectedPagesModal";
import {useIsAnyVideo} from "./useIsAnyVideo";
import {usePutInstagram} from "./tanstackQuery/usePutInstagram";
import {QueryVideoFilter} from "../../api/video/getVideos";
import {queryClient} from "../../api/client";
import {queryKey} from "../query/keys";

export const useOnSuccessMetaConnected = () => {
  const dispatch = useDispatch();
  const isConnecting = useSelector(selectUser).isWaitingVideosFromInstagram;
  const navigate = useNavigate();
  const intervalRef = useRef<number>();
  const {isFBReview} = useSelector(selectUser);
  const {refetch} = useIsAnyVideo();


  const redirection = () => {
    intervalRef.current = window.setInterval(async () => {
      const videoCountsPerFilter = await refetch().then(res => res.data)
      const isAnyInstagramVideo = videoCountsPerFilter?.INSTAGRAM ? videoCountsPerFilter.INSTAGRAM > 0 : false;
      if (isAnyInstagramVideo) {
        clearInterval(intervalRef.current)
        navigate('/library/source?subFilters=Instagram', {replace: true});
        dispatch(userActions.setIsWaitingVideosFromInstagram(false))
      }
    }, 450)
  }

  const {mutate: putInstagramToken, isIdle} = usePutInstagram({
    onSuccess: (res) => {
      if (isFBReview) {
        openSuccessModalFB(res.pagesWithAccount);
      }
      redirection()
      //ONBOARDING checking instagram connection
      checkAndRefetchOnboardingData();
    }
  });

  const onSuccess = async (response: SuccessResponse, callback?: {
    onSuccess?: () => void;
  }) => {
    dispatch(userActions.setIsWaitingVideosFromInstagram(true))

    putInstagramToken({
      instagramId: response.userID,
      longTermToken: response.accessToken
    })

  };

  const scope: string = useMemo(() => {
    const scopes = [
      'pages_show_list',
      'instagram_basic',
      'business_management',
      'instagram_manage_comments',
      'pages_read_engagement',
      'instagram_manage_insights',
    ]

    return scopes.join(',')
  }, [isFBReview])
  return {onSuccess, isConnecting, scope}
}
