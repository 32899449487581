import { Container, Title, Text, Group, Stack, Box } from '@mantine/core';
import { GradientButton } from 'src/components/GradientButton/GradientButtom';
import styles from './ErrorNotFound.module.scss';

import {FC, useEffect} from 'react';
import * as Sentry from "@sentry/react";
import {Link} from "react-router-dom";

export const ErrorNotFound: FC = () => {
  useEffect(() => {
    Sentry.captureMessage("ErrorNotFound component rendered", 'error');
    Sentry.forceLoad();
    try {
      Sentry.captureException(new Error('Error ErrorNotFound component rendered'));
    } catch (e) {
      console.error(e);
    }
    try {
      Sentry.captureSession()
    } catch (e) {
      console.error(e)
    }
    Sentry.forceLoad();
  }, []);
  return (
    <Container
      size="md"
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack align="center" spacing="xl" style={{ color: '#fff' }}>
        <Group align="center">
          <Title
            order={1}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -71%)',
              fontSize: '13rem',
              fontWeight: 'bold',
              zIndex: -1,
            }}
            className={styles.errorNumber}
          >
            404
          </Title>
          <Title>You have found a secret place</Title>
        </Group>
        <Text size="lg" align="center">
          Unfortunately, this is only a 404. <br></br>You may have mistyped the address, or the page
          has been moved to another URL.
        </Text>
        <Box mt={40}>
          <Link to="/" replace={true} reloadDocument={true}>
             <GradientButton >
              Take me back
            </GradientButton>
          </Link>
        </Box>
      </Stack>
    </Container>
  );
};
