import {useEffect} from 'react';
import {Box} from "@mantine/core";

export const SpecialErrorPage = () => {
  useEffect(() => {
    console.log('SpecialErrorPage rendered');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const s: any[] = []
    const b = s[5]
    const i = b.f.t
  }, []);
  return (
    <Box>
      special-error-test-page
      </Box>
  );
};

