import classNames from 'classnames';
import { FC, ReactElement, ReactNode } from 'react';
import styles from './PropWrapper.module.scss';
import {Tooltip} from "@mantine/core";

export interface PropWrapperProps {
  propName?: string;
  children: ReactElement | ReactElement[];
  subModule?: ReactNode;
  alignContentLeft?: boolean;
  columnContent?: boolean;
  showBorder?: boolean;
  tooltip?: string;
  propNameWithoutMargin?: boolean;
  classes?: {
    childrenWrapper?: string;
  }
}
export const PropWrapper: FC<PropWrapperProps> = ({
  propName,
  subModule,
  alignContentLeft,
  children,
  columnContent,
  showBorder,
  tooltip,
  propNameWithoutMargin,
  classes,
}) => {
  const mainStyles = classNames({
    [styles.container__main]: true,
    [styles.spaceBetween]: alignContentLeft,
    [styles.column]: columnContent,
  });
  const propNameStyles = classNames({
    [styles.propName]: true,
    [styles.propName__withoutMarging]: propNameWithoutMargin,
    [styles.bottomSpace]: columnContent,
  });

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.container__border]: showBorder,
  });

  const showSubmodule = Boolean(subModule)
  const childrenWrapperStyles = classNames(styles.control,classes?.childrenWrapper)

  return (
    <div className={containerStyles}>
      <Tooltip label={tooltip || ''} hidden={!tooltip}>
        <div className={mainStyles}>
          {propName && <span className={propNameStyles}>{propName}</span>}
          <div id="223" className={childrenWrapperStyles}>{children}</div>
        </div>
      </Tooltip>
      {showSubmodule && <div className={styles.container__submodule}>{subModule}</div>}
    </div>
  );
};
