import {useDispatch, useSelector} from 'react-redux';
import {SegmentSwitch} from 'src/components/SegmentSwitch/SegmentSwitch';
import {selectWidgetSettings, selectWidgetSettingsV2} from 'src/components/Widget/selectors';
import {BehaviorKey, BehaviorValue, widgetsActions} from 'src/components/Widget/store';
import {BehaviorAnimation, BehaviorAutoplay, WidgetViewType} from 'src/components/Widget/types';
import {PropWrapper} from '../PropWrapper';
import styles from './LandingPageSettings.module.scss';
import {Button, CopyButton, Flex, TextInput} from "@mantine/core";
import React from "react";
import {notifications} from "@mantine/notifications";

const autoplayData = [
  {
    value: BehaviorAutoplay.off,
    label: 'Off',
  },
  {
    value: BehaviorAutoplay.enable,
    label: 'Enable',
  },
  {
    value: BehaviorAutoplay.hover,
    label: 'On hover',
  },
];

const animationData = [
  {
    value: BehaviorAnimation.off,
    label: 'Off',
  },
  {
    value: BehaviorAnimation.pulse,
    label: 'Pulse',
  },
  {
    value: BehaviorAnimation.shine,
    label: 'Shine',
  },
];

export const LandingPageSettings = () => {
  const {landingPage = 'No link. Please contact a support'} = useSelector(selectWidgetSettingsV2) ?? {};

  const copyToClipboard = async () => {
    if (landingPage) {
      await navigator.clipboard.writeText(landingPage);
      notifications.show({
        autoClose: 2000,
        color: 'green.1',
        message: 'Landing page url copied to clipboard',
      })
    }
  }

  return (
    <>
      <PropWrapper
        classes={{
          childrenWrapper: styles.propWrapperChildrenWrapper,
        }}
      >
        <Flex gap={12} w="100%">
          <TextInput
            w="100%"
            value={landingPage}
            placeholder="No link. Please contact a support"
            disabled
          />
          <CopyButton value={landingPage}>
            {({ copied, copy }) => (
              <Button onClick={copy} color="green.1" w={100} >
                {copied ? 'URL copied' : 'Copy URL'}
              </Button>
            )}
          </CopyButton>
        </Flex>
      </PropWrapper>
    </>
  );
};
