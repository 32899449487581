import { FC } from 'react';
import propWrapperStyles from '../../PropWrapper.module.scss';
import { ColorInput, NumberInput, Slider, Box } from '@mantine/core';
import styles from './BorderSubmodule.module.scss';

export type BorderHandlerParams = {
  key: 'borderColor';
  value: string;
} | {
  key: 'borderThickness';
  value: number;
}

type BorderColorHandler = (data: BorderHandlerParams) => void;
type BorderHandler =  BorderColorHandler ;

interface SubmoduleProps {
  handler: BorderHandler;
  buyButton: {
    borderColor: string;
    borderThickness: number;
  };
}

export const BorderSubmodule: FC<SubmoduleProps> = ({ handler, buyButton }) => {
  return (
    <Box className={propWrapperStyles.submodule__container}>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Color</span>
        <ColorInput
          w={171}
          format="hex"
          placeholder="Pick a color"
          value={buyButton?.borderColor}
          onChange={(val) => handler({
            key: 'borderColor',
            value: val,
          })}
        />
      </Box>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Thickness</span>
        <div className={styles.slider}>
          <Slider
            value={buyButton?.borderThickness}
            onChange={(val) => handler({
              key: 'borderThickness',
              value: val,
            })}
            w={160}
            mr={12}
            max={10}
            min={0}
          />
          <NumberInput
            color="green"
            value={typeof buyButton?.borderThickness === 'number' ? buyButton.borderThickness : 0}
            onChange={(val) =>{
              if(typeof val === 'number'){
                handler({
                  key: 'borderThickness',
                  value: val,
                });
              }
            }}
            hideControls
            styles={() => ({
              input: { width: '42px', height: '26px', minHeight: '26px' },
            })}
            min={0}
            max={10}
            step={1}
            mr={6}
          />
          <div className={styles.slider__units}>px</div>
        </div>
      </Box>
    </Box>
  );
};
