import { ColorInput, NumberInput, Slider, Switch } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { SegmentSwitch } from 'src/components/SegmentSwitch/SegmentSwitch';
import { PropWrapper } from '../PropWrapper';
import { PropWithSwitchAutoScroll } from '../PropWithSwitchAutoScroll/PropWithSwitchAutoScroll';
import styles from './VideoPlayerSettings.module.scss';
import {
  buyVideoPlayerButtonSettingsInit,
  LayoutKey,
  LayoutKeyV2,
  LayoutValue,
  LayoutValueV2,
  SetLayoutV2ActionPayload, viewButtonSettingsInit,
  widgetsActions, widgetSettingsInit
} from 'src/components/Widget/store';
import {
  selectWidgetSettings,
  selectWidgetSettingsV2,
} from 'src/components/Widget/selectors';
import {VideoPlayerBuyButtonSubmodule, ViewProductButtonSubmodule} from './VideoPlayerSettings.submodules';
import {PreviewCTAElementPosition, VideoPlayerProductCardCTAButtons, WidgetViewType} from 'src/components/Widget/types';
import {isEmptyObject} from "../../../../../../shared/helpers/isEmptyObject";
import {useUpdateWidgetSettingsV2} from "../../../../../../shared/hooks/useUpdateWidgetSettingsV2";

const sizeData = [
  {
    value: 'sm',
    label: 'Small',
  },
  {
    value: 'md',
    label: 'Medium',
  },
  {
    value: 'lg',
    label: 'Large',
  },
];

export const VideoPlayerSettings = () => {
  const dispatch = useDispatch();
  // const { layout, general } = useSelector(selectWidgetSettings) ?? {};
  const {layout: layoutV2} = useSelector(selectWidgetSettingsV2) ?? {};

  const handler = (key: LayoutKey, value: LayoutValue) => {
    dispatch(widgetsActions.setLayout({ key, value }));
  };

  const {
    handler: handlerV2,
  } = useUpdateWidgetSettingsV2();


  const buyButtonHandler = () => {
    if(!layoutV2?.videoPlayerCTAButtons.buyButtonEnabled && (!layoutV2?.videoPlayerCTAButtons.buyButtonSettings || isEmptyObject(layoutV2?.videoPlayerCTAButtons.buyButtonSettings))) {
      handlerV2({
        path: 'videoPlayerCTAButtons.buyButtonPosition',
        value: VideoPlayerProductCardCTAButtons.OverlayOnCard,
      })
      handlerV2({
        path: 'videoPlayerCTAButtons.buyButtonSettings',
        value: buyVideoPlayerButtonSettingsInit,
      })
    }
    const newValue = !layoutV2?.videoPlayerCTAButtons.buyButtonEnabled;
    handlerV2({
      path: 'videoPlayerCTAButtons.buyButtonEnabled',
      value: newValue,
    })
  }

  const viewProductButtonToggle = () => {
    if(!layoutV2?.videoPlayerCTAButtons.viewProductButtonEnabled && (!layoutV2?.videoPlayerCTAButtons.viewProductButtonSettings || isEmptyObject(layoutV2?.videoPlayerCTAButtons.viewProductButtonSettings))) {
      handlerV2({
        path: 'videoPlayerCTAButtons.viewProductButtonPosition',
        value: VideoPlayerProductCardCTAButtons.OverlayOnCard,
      })
      handlerV2({
        path: 'videoPlayerCTAButtons.viewProductButtonSettings',
        value: viewButtonSettingsInit,
      })
    }
    const newValue = !layoutV2?.videoPlayerCTAButtons.viewProductButtonEnabled;
      handlerV2({
        path: 'videoPlayerCTAButtons.viewProductButtonEnabled',
        value: newValue,
      })
  }


  return (
    <>
      <PropWithSwitchAutoScroll
        showBorder={true}
        propName='View Product Button'
        subModule={
          !layoutV2?.videoPlayerCTAButtons.viewProductButtonEnabled ? null : <ViewProductButtonSubmodule handler={handlerV2} layout={layoutV2}/>
        }
        checked={layoutV2?.videoPlayerCTAButtons.viewProductButtonEnabled}
        onChange={viewProductButtonToggle}
      />
      <PropWithSwitchAutoScroll
        propName='Buy Button'
        subModule={
          !layoutV2?.videoPlayerCTAButtons.buyButtonEnabled ? null : <VideoPlayerBuyButtonSubmodule handler={handlerV2} layout={layoutV2}/>
        }
        checked={layoutV2?.videoPlayerCTAButtons.buyButtonEnabled}
        onChange={buyButtonHandler}
      />
    </>
  );
};
